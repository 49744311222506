import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ConfirmWrapper = styled.div`
  width: 1200px;
  border-radius: 0px;
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  z-index: 30;
  @media (max-width: 1200px){
    width: 950px;
  }
  @media (max-width: 991px){
    position: relative;
    background-color: transparent;
    border: 0px;
    margin: 0 auto;
    left: inherit;
    width: 100%;
    margin-top: 50px;
    display: block;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #31376C;
  @media (max-width: 991px){
    background-color: #31376C;
  }
`;

export const ConfirmContent = styled.div`
  padding: 40px 40px;
  color: #31376C;
  text-align: center;
  button{
    display: inline-block;
    @media (max-width: 991px){
      padding: 0px 50px;
    }
  }
`;

export const ConfirmTitle = styled.div`
  margin-top: 20px;
  font-size: 32px;
  color: #31376C;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  @media (max-width: 991px){
    color: #31376C;
  }
`;

export const ConfirmMessage = styled.div`
  margin-top: 20px;
  font-size: 12px;
  text-transform: normal;
  color: #31376C;
  @media (max-width: 991px){
    color: #31376C;
  }
`;

export const ResumeVideo = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  margin: 0 auto;
  margin-top: 0px;
  @media (max-width: 767px){
    width: 100%;
    height: 200px;
  }
`;

export const ShareTitle = styled.div`
  font-size: 12px;
  color: #31376C;
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #31376C;
  
  a{
    background-color: ${props => props.color};
    background-color: transparent;
    color: #31376C;
    display: flex;
    font-size: 11px;
    padding: 5px 5px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
  }
  img{
    height: 30px;
    width: auto;
    margin-right: 5px;
  }
`;

export const Phone = styled.img`
  width: 650px;
  height: auto;
  position: relative;
  @media (max-width: 991px){
    display: none;
  }
`;