
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";


export const FooterWrapper = styled.footer`
  position: fixed;
  display: block;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px;
  bottom: 10px;
  right: 10px;
  text-align: right;
  @media (max-width: 991px){
    position: relative;
    left: inherit;
    bottom: inherit;
    margin-top: 0px;
    text-align: center;
    display: inline-block;
    justify-content: inherit;
    width: 100%;
  }
`;

export const ListFooterLink = styled.ul`
  margin: 0px;
  padding: 0px 10px;
  @media (max-width: 991px){
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 0px;
    padding-bottom: 30px;
  }
  &.right{
    text-align: left;
    float: left;
    padding-left: 20px;
    @media (max-width: 991px){
        float: inherit;
        text-align: center;
      }
    li{
      text-align: right;
      float: right;
      &:first-child{
        margin-bottom: 5px;
      }
      @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: inline-block;
      }
    }
  }
  span{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
  }
  li {
    list-style: none;
    float: right;
    padding: 0px 4px;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    @media (max-width: 991px){
        float: inherit;
        padding: 0px 10px;
        text-align: center;
        color: #fff;
        display: inline-block;
      }
    &:last-child{
      border-right: 0px;
    }
    a{
      color: #000;
      text-decoration: inherit;
      margin: 0px;
      font-weight: 400;
      @media (max-width: 991px){
        color: #000;
      }
      &:hover{
        color: #000;
      }
    }
  }
`;
