import * as React from 'react';
import Layout from '../layout';
import {  Safari, Text, Title,Catchline, SubtitleMobile, ButtonMobile, RecordWrapper, ListModules, WebcamWrapper, RecordHeader, StepNumber, ActionsWrapper, ModuleListItem, ModuleContainer, OnDesktop, OnMobile} from './styled';
import { Link, useHistory } from 'react-router-dom';


import { Button } from '../../widgets/globaleStyledWidget';
import { colors, lang } from '../../variables';


import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';

import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/videojs.record.js';
import livstickApp from '../../models/livstick';

import {FormattedMessage, useIntl} from 'react-intl';



const RecordPage:React.FunctionComponent = () => {

  const [moduleIndex, setModuleIndex] = React.useState<number>(0);
  const [playerState, setPlayerState] = React.useState<number>(0);
  const [player, setPlayer] = React.useState<any>(null);

  const history = useHistory();
  const intl = useIntl();
  

  const videoEl = React.useRef(null);
  const fileEl = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    console.log('ref', videoEl);

    fileEl.current.addEventListener('change', (e) => {
      livstickApp.videoFile = fileEl.current.files[0];
      if ((livstickApp.videoFile.size / (1024*1024)) > 30 ) return alert(intl.formatMessage({id: "player_txt_warning_toobig"}));
      if (!livstickApp.videoFile.type.includes('video') ) return alert(intl.formatMessage({id: "player_txt_warning_format"}));
      history.push('message');
    })
    
  }, [])

  const uploadFile = () => {
    fileEl.current.click();
    
  }
  const validate = () => {

    history.push('message')
  }

  return <Layout>
    <RecordWrapper>
      
      <ModuleContainer>
      <input type="file" style={{display: 'none'}} ref={fileEl}/>
        <div>
          <OnDesktop>
            <Safari>
              <StepNumber><FormattedMessage id="global_txt_step"/> 1/2</StepNumber>
              <Title><FormattedMessage id="player_txt_safari_title" /></Title>
              <Text><FormattedMessage id="player_txt_safari" /></Text>
              <ActionsWrapper>
                {/*ROMAIN CHANGEMENT BUTTON*/}{[0].includes(playerState) && <Button className="button-mobile" color={colors.secondary} hover="secondary" onClick={uploadFile}><FormattedMessage id="player_txt_button_import"/></Button>}
                {/*ROMAIN CHANGEMENT BUTTON*/}{[3].includes(playerState) && <Button className="button-mobile" color={colors.secondary} hover="secondary" onClick={validate}><FormattedMessage id="player_txt_button_send"/></Button>}
              </ActionsWrapper>
            </Safari>
          </OnDesktop>
          <OnMobile>
            
            <SubtitleMobile><FormattedMessage id="player_txt_title" /></SubtitleMobile>
            {[0].includes(playerState) && <ButtonMobile className="button-mobile button-special" onClick={uploadFile}><FormattedMessage id="player_txt_video_button" /></ButtonMobile>}
          </OnMobile> 
        </div>
      </ModuleContainer>
      
    </RecordWrapper>
    
  </Layout>
}

export default RecordPage;